import Container from 'components/Layouts/Container/Container';
import { useEffect, useState } from 'react';
import ZpImage from 'components/UI/Image/ZpImage';
import ZpButton from 'components/UI/Button/ZpButton';
import { useIsServerSide } from 'core/hooks/useIsServerSide';
import { routes } from 'core/constants/routes';
import ZpLink from 'components/UI/Link/ZpLink';

const HomagePageHeader = () => {
  const [isScroll, setIsScroll] = useState(false);
  const { isServerSide } = useIsServerSide();

  useEffect(() => {
    if (!isServerSide) {
      window.onscroll = function () {
        if (window.scrollY > 50) {
          setIsScroll(true);
        } else {
          setIsScroll(false);
        }
      };
    }
  }, [isServerSide]);

  return (
    <nav className={`${isScroll ? 'shadow-md' : ''} sticky top-0 bg-white-color z-[999] py-4`}>
      <Container>
        <div className='flex items-center space-x-5 space-x-reverse justify-between'>
          <ZpLink href={routes.ROOT()}>
            <ZpImage width={150} height={150} src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/logo/logo-text.svg`} alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''} />
          </ZpLink>

          <ul className='md:flex space-x-8 space-x-reverse hidden'>
            <li>
              <ZpLink href={routes.ROOT('feature')} className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph'>
                ویژگی‌ ها و امکانات
              </ZpLink>
            </li>
            <li>
              <ZpLink href={routes.ROOT('plan')} className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph'>
                پلن و قیمت ها
              </ZpLink>
            </li>
            {/*<li>*/}
            {/*	<Link className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph' to='customer' offset={-150} spy={true} smooth={true} duration={500}>*/}
            {/*		{'همراهان ' + process.env.NEXT_PUBLIC_BRAND_NAME_FA}*/}
            {/*	</Link>*/}
            {/*</li>*/}
            <li>
              <ZpLink href={routes.ROOT('support')} className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph'>
                پشتیبانی
              </ZpLink>
            </li>

            {/*<li>*/}
            {/*  <ZpLink className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph' href={routes.RULES}>*/}
            {/*    قوانین و مقررات*/}
            {/*  </ZpLink>*/}
            {/*</li>*/}

            {/*<li>*/}
            {/*  <ZpLink className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph' href={routes.PRIVACY}>*/}
            {/*    سیاست محرمانگی*/}
            {/*  </ZpLink>*/}
            {/*</li>*/}
          </ul>

          <ZpLink external href={routes.LOGIN()}>
            <ZpButton>ثبت نام | ورود</ZpButton>
          </ZpLink>
        </div>
      </Container>
    </nav>
  );
};

export default HomagePageHeader;
