import Container from 'components/Layouts/Container/Container';
import React, { FC } from 'react';
import ZpImage from 'components/UI/Image/ZpImage';
import ZpButton from 'components/UI/Button/ZpButton';
import { routes } from 'core/constants/routes';
import ZpLink from 'components/UI/Link/ZpLink';
import { usePlanListQuery } from 'core/api/react-query/usePlanListQuery';
import { getImageUrl } from 'core/utils/getImageUrl';
import { IPlanFeature } from 'core/types/Models/planType';
import { separator } from 'core/utils/default';
import { is } from 'immutable';
import HomeLandingPlanSkeleton from 'components/HomeLanding/components/HomeLandingPlan/HomeLandingPlanSkeleton';

interface IPlan {
  icon: string;
  type: string;
  price: number;
  button: string;
  features: IPlanFeature[];
}

const HomeLandingPlan = () => {
  const { data: plans, isFetching } = usePlanListQuery();

  if (isFetching) return <HomeLandingPlanSkeleton />;

  return (
    <div id='plan' className='relative pt-28'>
      <ZpImage
        width={1200}
        height={1200}
        src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/plan/plan-bg.svg`}
        className='absolute md:block hidden left-1/2 -translate-x-1/2 top-0 -z-10'
        alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
      />
      <div className='absolute md:hidden block left-1/2 -translate-x-1/2 top-0 -z-10 w-full max-w-2xl max-h-full overflow-hidden'>
        <ZpImage
          width={900}
          height={800}
          src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/plan/plan-bg-mobile.svg`}
          alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
        />
      </div>

      <Container>
        <div className='space-y-10'>
          <div className='flex justify-center'>
            <div className='relative'>
              <h2 className='text-2xl sm:text-4xl font-bold text-center'>{`پلن‌های ${process.env.NEXT_PUBLIC_BRAND_NAME_FA}`}</h2>
              <div className='w-10 h-1 bg-secondary-color-main absolute top-12 right-0' />
            </div>
          </div>

          <p className='text-center max-w-4xl mx-auto w-full text-color-paragraph text-lg'>
            {`استفاده از لینک‌ها و ساخت درگاه پرداخت رایگان است. همچنین به مدت یک‌ماه می‌توانید بدون هیچ هزینه‌ای از تمامی بخش‌های حرفه‌ای ${process.env.NEXT_PUBLIC_BRAND_NAME_FA} استفاده کنید و از کارایی آن مطلع شوید.`}
          </p>
        </div>

        <div className='mt-16 flex md:flex-row flex-col justify-center items-stretch w-full gap-5 md:gap-y-0 gap-y-14'>
          {plans?.data?.map(({ id, name, icon, price, features }) => (
            <PlanItem key={id} type={name} icon={icon} button={`${price ? name : 'رایگان'} شروع کنید`} price={price} features={features} />
          ))}
        </div>
      </Container>
    </div>
  );
};

const PlanItem: FC<IPlan> = ({ type, icon, price, features, button }) => {
  return (
    <div className='shadow-md self-stretch flex-1 max-w-xs w-full relative border border-gray-color-200 bg-white-color flex flex-col space-y-8 items-center rounded-xl px-10 py-8'>
      <ZpImage src={getImageUrl(icon)} alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''} width={100} height={100} />

      <p className='text-3xl font-bold text-gray-color-500'>{type}</p>
      <span className='text-4xl text-secondary-color-main font-bold'>
        {price ? (
          <span>
            {`${separator(price)}`} <span className='text-lg'>تومان</span>
          </span>
        ) : (
          'رایگان'
        )}
      </span>

      <div className='w-full text-center flex-grow flex flex-col justify-center items-center'>
        {features.map(({ name, id }) => (
          <div className='last:border-b-0 py-3 border-b-2 text-color-paragraph w-full' key={id}>
            {name}
          </div>
        ))}
      </div>

      <ZpLink target='_blank' external href={routes.LOGIN(`redirect=${routes.PANEL_PLAN}`)} className='absolute -bottom-5'>
        <ZpButton>{button}</ZpButton>
      </ZpLink>
    </div>
  );
};

export default HomeLandingPlan;
