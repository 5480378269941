import { FC } from 'react';
import ZpImage from 'components/UI/Image/ZpImage';
import Container from 'components/Layouts/Container/Container';
import * as process from 'process';

interface IFeatureItem {
  title: string;
  id: number;
  isPre?: boolean;
}
const HomeLandingFeature = () => {
  const features: IFeatureItem[] = [
    {
      id: 1,
      title: 'یک لینک شامل صد ها لینک',
    },
    {
      id: 2,
      title: 'درگاه پرداخت زرین پال',
    },
    {
      id: 3,
      title: 'رابط کاربری زیبا و ساده',
    },
    {
      id: 4,
      title: 'ثبت نام و امکانات رایگان',
    },
  ];
  return (
    <div id='feature' className='pt-28'>
      <Container className='mb-56'>
        <div className='md:px-10 px-5 pt-10 md:max-h-72 max-h-[430px] bg-primary-color-50 rounded-lg'>
          <div className='flex justify-between md:px-10 p-0 space-x-reverse space-x-5 md:flex-row flex-col md:items-start items-center md:space-y-0 space-y-5'>
            <div className='relative'>
              <h2 className='text-3xl font-bold w-44'>چرا {process.env.NEXT_PUBLIC_BRAND_NAME_FA} ؟</h2>
              <div className='w-10 h-1 bg-secondary-color-main absolute top-10 right-0' />
            </div>

            <p className='max-w-lg w-full text-color-placeholder leading-[2]'>
              با استفاده از {process.env.NEXT_PUBLIC_BRAND_NAME_FA} می توانید یک لینک که شامل لینک شبکه های اجتماعی شما ، پیام رسان های شما ، و از همه مهمتر درگاه پرداختی برای
              حمایت مالی از شما یا فروش محصولات، داشته باشید.
            </p>
          </div>

          <div className='grid md:grid-cols-4 grid-cols-2 gap-x-5 sm:gap-x-14 gap-y-5 relative md:top-10 top-5 md:px-10 p-0'>
            {features.map((feature) => (
              <FeatureItem key={feature.id} {...feature} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

const FeatureItem: FC<IFeatureItem> = ({ title, id }) => {
  return (
    <div className='space-y-5'>
      <div className='h-full flex flex-col text-center items-center space-y-5 space-x-reverse bg-natural-color-50 shadow-md md:py-14 py-8 px-3 rounded-lg'>
        <ZpImage width={40} height={40} src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/feature/${id}.svg`} alt={title} />
        <h2 className='md:text-xl text-base font-bold'>{title}</h2>
      </div>
    </div>
  );
};

export default HomeLandingFeature;
