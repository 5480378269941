import ZpImage from 'components/UI/Image/ZpImage';
import Container from 'components/Layouts/Container/Container';
import { ImLocation } from 'react-icons/all';

const HomeLandingSupport = () => {
  return (
    <div id='support' className='pt-28'>
      <Container className='space-y-16'>
        <h2 className='text-2xl sm:text-4xl font-bold text-center'>ما همراه و پشتیبان شما هستیم!</h2>

        <div className='flex md:flex-row-reverse flex-col space-y-10 md:space-y-0 md:space-x-6 space-x-reverse items-center justify-between'>
          <ZpImage
            className='w-full max-w-2xl'
            width={500}
            height={500}
            src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/support.svg`}
            alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
          />

          <div className='max-w-[450px] w-full space-y-7 flex flex-col items-start justify-center'>
            <div className='space-y-6 mx-auto'>
              <div className='flex items-start space-x-reverse space-x-5'>
                <ZpImage
                  className='relative bottom-3'
                  width={30}
                  height={30}
                  src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/phone.svg`}
                  alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
                />

                <div>
                  <h3 className='text-color-placeholder text-xl leading-none'>شماره تماس‌ها</h3>

                  <div className='mt-4 space-y-2'>
                    <div className='grid grid-cols-2'>
                      <a className='font-bold text-lg' href='tel:0214568000'>
                        021-4568000
                      </a>
                      <span>(پشتیبانی خریداران)</span>
                    </div>

                    <div className='grid grid-cols-2'>
                      <a className='font-bold text-lg' href='tel:02141239'>
                        021-41239
                      </a>
                      <span>(پشتیبانی پذیرندگان)</span>
                    </div>

                    <div className='grid grid-cols-2'>
                      <a className='font-bold text-lg' href='tel:02145628700'>
                        021-45628700
                      </a>
                      <span>(پشتیبانی {process.env.NEXT_PUBLIC_BRAND_NAME_FA})</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex items-start space-x-reverse space-x-5'>
                <ZpImage
                  className='relative bottom-3'
                  width={30}
                  height={30}
                  src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/clock.svg`}
                  alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
                />

                <div>
                  <h3 className='text-color-placeholder text-xl leading-none'>ساعات کاری</h3>

                  <div className='mt-4 space-y-2'>
                    <p className='font-bold text-lg'>شنبه تا چهارشنبه</p>
                    <p className='font-bold text-lg'>از ساعت 9 صبح الی 5 عصر</p>
                  </div>
                </div>
              </div>

              <div className='flex items-start space-x-reverse space-x-5'>
                <ImLocation className='relative bottom-3' size={30} color='var(--primary-color-main)' />

                <div>
                  <h3 className='text-color-placeholder text-xl leading-none'>آدرس</h3>

                  <div className='mt-4 space-y-2'>
                    <p className='font-bold text-lg'>تهران، یوسف آباد، خیابان بیستم، پلاک 61، طبقه همکف</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeLandingSupport;
