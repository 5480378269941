'use client';

import HomePageFooter from 'components/Layouts/HomePageFooter/HomePageFooter';
import MainLayout from 'components/Layouts/MainLayout/MainLayout';
import { FC, ReactNode, useEffect } from 'react';
import HomagePageHeader from 'components/Layouts/HomagePageHeader/HomagePageHeader';
import { FloatButton } from 'antd';
import { BsArrowUpCircle } from 'react-icons/bs';
import { usePathname } from 'next/navigation';

export interface IRootLayout {
  children: ReactNode;
}

const RootLayout: FC<IRootLayout> = ({ children }) => {
  const pathname = usePathname();

  useEffect(() => {
    pathname !== '/' && window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <HomagePageHeader />
      <MainLayout>{children}</MainLayout>
      <HomePageFooter />

      <FloatButton.BackTop icon={<BsArrowUpCircle color='var(--primary-color-main)' size={20} />} />
    </>
  );
};

export default RootLayout;
