import ZpImage from 'components/UI/Image/ZpImage';
import Container from '../Container/Container';
import { routes } from 'core/constants/routes';
import ZpButton from 'components/UI/Button/ZpButton';
import { IMAGE_PUBLIC_BASE_URL } from 'core/utils/default';
import ZpLink from 'components/UI/Link/ZpLink';

const HomePageFooter = () => {
  const socials: { id: number; title: string; url: string }[] = [
    { id: 1, title: 'instagram', url: 'https://google.com' },
    { id: 2, title: 'telegram', url: 'https://google.com' },
    { id: 3, title: 'whatsapp', url: 'https://google.com' },
    { id: 4, title: 'email', url: 'https://google.com' },
  ];

  return (
    <footer className='footer bg-secondary-color-10'>
      <Container>
        <div className='mb-0 flex flex-col  justify-center items-center space-y-8 py-10'>
          <div className='relative flex items-center space-x-6 space-x-reverse'>
            <ZpImage
              className='sm:absolute -top-24 -right-24'
              width={100}
              height={100}
              src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/flower.svg`}
              alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
            />
            <h2 className='text-xl font-bold'>هیچوقت برای شروع دیر نیست همین حالا حلقه ارتباطی خود را بسازید</h2>
          </div>

          <ZpLink external href={routes.LOGIN()}>
            <ZpButton>ثبت نام و ساخت رایگان لینک</ZpButton>
          </ZpLink>

          <ZpImage width={200} height={200} src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/logo/logo-text.svg`} alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''} />

          <ZpImage
            onClick={() => window.open('https://trustseal.enamad.ir/?id=414867&code=Z9St41A3XWqQ6iTJ69KREo8830x4tIAs', '_newtab')}
            width={100}
            height={100}
            src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/enamad-one-star.png`}
            alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
            referrerPolicy='origin'
          />

          <div className='flex items-center space-x-6 space-x-reverse'>
            {socials.map(({ url, id, title }) => (
              <a target='_blank' key={id} href={url} rel='noreferrer'>
                <ZpImage width={25} height={25} src={`${IMAGE_PUBLIC_BASE_URL}/landing/social/${title}.svg`} alt={title} />
              </a>
            ))}
          </div>
        </div>
      </Container>

      <div className='bg-primary-color-50'>
        <Container>
          <ul className='flex xs:flex-row flex-col xs:space-x-8 space-x-0 xs:space-x-reverse py-3 items-center space-y-5 xs:space-y-0 justify-center'>
            <li>
              <ZpLink className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph' href={routes.ROOT('feature')}>
                ویژگی‌ ها و امکانات
              </ZpLink>
            </li>
            <li>
              <ZpLink className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph' href={routes.ROOT('plan')}>
                پلن و قیمت ها
              </ZpLink>
            </li>
            {/*<li>*/}
            {/*	<Link className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph' to='customer' offset={-150} spy={true} smooth={true} duration={500}>*/}
            {/*		{'همراهان ' + process.env.NEXT_PUBLIC_BRAND_NAME_FA}*/}
            {/*	</Link>*/}
            {/*</li>*/}
            <li>
              <ZpLink className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph' href={routes.ROOT('support')}>
                پشتیبانی
              </ZpLink>
            </li>

            <li>
              <ZpLink className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph' href={routes.RULES}>
                قوانین و مقررات
              </ZpLink>
            </li>
            <li>
              <ZpLink className='pb-3 hover:text-primary-color-main cursor-pointer text-color-paragraph' href={routes.PRIVACY}>
                سیاست محرمانگی
              </ZpLink>
            </li>
          </ul>
        </Container>
      </div>
    </footer>
  );
};

export default HomePageFooter;
