import ZpImage from 'components/UI/Image/ZpImage';
import { routes } from 'core/constants/routes';
import ZpButton from 'components/UI/Button/ZpButton';
import Container from 'components/Layouts/Container/Container';
import ZpLink from 'components/UI/Link/ZpLink';
import React from 'react';

const HomeLandingVisual = () => {
  return (
    <Container className='pt-28'>
      <div className='space-y-7'>
        <div className='space-y-6'>
          <h2 className='text-2xl sm:text-4xl font-bold text-center'>{`در 3 مرحله ${process.env.NEXT_PUBLIC_BRAND_NAME_FA} خود را بسازید! به همین راحتی...`}</h2>
        </div>

        <div className='flex md:flex-row md:space-y-0 space-y-6 flex-col justify-center items-center space-x-5 space-x-reverse'>
          <div className='max-w-lg w-full'>
            <ZpImage
              className='w-full'
              width={700}
              height={700}
              src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/visual-identity.svg`}
              alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
            />
          </div>

          <div className='space-y-7 relative top-7'>
            <div className='flex md:flex-row flex-col items-center md:items-start space-x-6 space-x-reverse'>
              <ZpImage
                src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/step-1.svg`}
                className='md:block hidden'
                alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
                width={75}
                height={75}
              />
              <ZpImage
                src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/step-1-mobile.svg`}
                className='block md:hidden'
                alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
                width={75}
                height={75}
              />

              <div className='space-y-3 md:text-right text-center'>
                <strong className='text-xl'>ثبت نام</strong>
                <p className='text-color-paragraph'>به صورت کاملا رایگان لینک خود را بسازید!</p>
              </div>
            </div>

            <div className='flex md:flex-row flex-col items-center md:items-start space-x-6 space-x-reverse'>
              <ZpImage
                src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/step-2.svg`}
                className='md:block hidden'
                alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
                width={75}
                height={75}
              />
              <ZpImage
                src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/step-2-mobile.svg`}
                className='block md:hidden'
                alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
                width={75}
                height={75}
              />

              <div className='space-y-3 md:text-right text-center'>
                <strong className='text-xl'>تعریف لینک</strong>
                <p className='text-color-paragraph'>لینک های مورد نیاز خود را تعریف کنید.</p>
              </div>
            </div>

            <div className='flex md:flex-row flex-col items-center md:items-start space-x-6 space-x-reverse'>
              <ZpImage
                src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/step-3.svg`}
                className='md:block hidden'
                alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
                width={75}
                height={75}
              />
              <ZpImage
                src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/step-3-mobile.svg`}
                className='block md:hidden'
                alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
                width={75}
                height={75}
              />

              <div className='space-y-3 md:text-right text-center'>
                <strong className='text-xl'>اشتراک گذاری لینک</strong>
                <p className='text-color-paragraph'>لینک رینگ را در شبکه های اجتماعی خود منتشر کنید.</p>
              </div>
            </div>

            <div className='flex items-start space-x-6 space-x-reverse'>
              <ZpImage
                src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/landing/step-3.svg`}
                className='invisible'
                alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''}
                width={75}
                height={75}
              />

              <ZpLink external href={routes.LOGIN()}>
                <ZpButton>رایگان شروع کنید</ZpButton>
              </ZpLink>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HomeLandingVisual;
