import ZpSkeleton from 'components/UI/Skeleton/ZpSkeleton';
import Container from 'components/Layouts/Container/Container';

const HomeLandingPlanSkeleton = () => {
  return (
    <Container className='flex items-center justify-center md:space-x-10 md:space-y-0 md:flex-row flex-col space-y-10 md:space-x-reverse'>
      {[1, 2, 3].map((data) => (
        <Item key={data} />
      ))}
    </Container>
  );
};

const Item = () => {
  return (
    <div className='flex flex-col border border-gray-color-200 w-full justify-between h-full px-5 py-10 shadow-md rounded-lg'>
      <div className='space-y-10'>
        <div className='text-center'>
          <div className='space-y-8'>
            <div className='space-x-reverse space-x-3 flex items-center justify-center'>
              <ZpSkeleton width={100} height={100} circle />
            </div>

            <div>
              <ZpSkeleton height={30} />
            </div>
            <div>
              <ZpSkeleton height={30} />
            </div>
            <div>
              <ZpSkeleton height={30} />
            </div>
            <div>
              <ZpSkeleton height={30} />
            </div>

            <div className='flex items-center space-x-5 justify-center space-x-reverse'>
              <ZpSkeleton height={30} width={150} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLandingPlanSkeleton;
