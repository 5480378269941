import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/useQueryHook';
import api from 'core/services/api/useApi';
import { AxiosError } from 'axios';
import { IPlanList } from 'core/types/Models/planType';

export const usePlanListQuery = () => {
  return useQueryHook(
    [endPointUrls.PLAN_LIST],
    () => api.get(endPointUrls.PLAN_LIST),
    {
      select: (res: any) => res.data,
    },
    false
  ) as UseQueryResult<IPlanList, AxiosError>;
};
