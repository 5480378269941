'use client';

import HomaLandingHead from 'components/HomeLanding/components/HomaLandingHead/HomaLandingHead';
import HomeLandingFeature from 'components/HomeLanding/components/HomeLandingFeature/HomeLandingFeature';
import HomeLandingVisual from 'components/HomeLanding/components/HomeLandingVisual/HomeLandingVisual';
import HomeLandingPlan from 'components/HomeLanding/components/HomeLandingPlan/HomeLandingPlan';
import HomeLandingSupport from 'components/HomeLanding/components/HomeLandingSupport/HomeLandingSupport';
import RootLayout from 'components/Layouts/PagesLayouts/RootLayout/RootLayout';
// import HomeLandingCustomer from 'components/HomeLanding/components/HomeLandingCustomer/HomeLandingCustomer';

const HomeLanding = () => {
  return (
    <RootLayout>
      <div>
        <HomaLandingHead />
        <HomeLandingFeature />
        <HomeLandingPlan />
        <HomeLandingVisual />
        {/*<HomeLandingCustomer />*/}
        <HomeLandingSupport />
      </div>
    </RootLayout>
  );
};

export default HomeLanding;
